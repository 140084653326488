<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nueva Junta de Aclaración</h1>
        <hr class="">
      </v-col>
      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#form">
            Junta de Aclaraciones y Unidad de Vigilancia 6.0
          </v-tab>
          <v-tab-item value="form">
            <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Parrafo por Defecto</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Parrafo por Defecto</h1>
                        <trumbowyg
                            v-model="data.parrafo_default"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Parrafo por Defecto"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Dudas y respuesas proveedores</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Dudas y respuesas proveedores
                        </h1>
                        <trumbowyg
                            v-model="data.dudas_respuestas"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Dudas y respuesas proveedores"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Usuario Requiriente</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Usuario Requiriente
                        </h1>
                        <trumbowyg
                            v-model="data.usuario_requirente"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Usuario Requiriente"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  
                </v-expansion-panels>
                <v-col cols="12" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-col>
      

    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import FileInput from "../../components/FileInput";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
export default {
  name: "Tenders",
  data() {
    return {
      overlay: false,
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            ['fullscreen'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
      data: {
        tenderId: null,
        formatosId: null,

        parrafo_default: "",
        dudas_respuestas: "",
        usuario_requirente: "",

        p_hora_minutos: "",
        p_fecha_letra: "",

        r_hora_minutos: "",
        r_fecha_letra: "",

        documento_url: "",
        
        numero_oficio: ""
      },
    };
  },
  components: {
    FileInput,
    Overlay,
    Trumbowyg
  },
  created() {
    this.data.tenderId = parseInt(this.$route.params.id);
    this.data.formatosId = parseInt(this.$route.params.formatId);

  },
  methods: {
    ...mapActions('ja_attendance', ['createJAAttendance']),
    ...mapActions('ja_minutes', ['createJAMinutes']),
    ...mapActions('ja_publication', ['createJAPublication']),
    ...mapActions('ja_retirement', ['createJARetirement']),
    ...mapActions('ja_surveillance_unit', ['createJASurveillanceUnit']),
    async validateRequired(){
      if (!this.data.parrafo_default){
        this.sweetAlertWarning('Campo Parrafo por Defecto es necesario');
        return false
      }
      if (!this.data.dudas_respuestas){
        this.sweetAlertWarning('Campo Dudas y Respuestas es requerido');
        return false
      }
      if (!this.data.usuario_requirente){
        this.sweetAlertWarning('Campo Usuario Requerimiento es requerido');
        return false
      }
      return true
    },
    async validateRequiredPublicacion (){
      if (!this.data.p_hora_minutos){
        this.sweetAlertWarning('Campo Hora y Minutos es necesario');
        return false
      }
      if (!this.data.p_fecha_letra){
        this.sweetAlertWarning('Campo Fecha en Letra es requerido');
        return false
      }
      return true
    },
    async validateRequiredRetiro (){
      if (!this.data.r_hora_minutos){
        this.sweetAlertWarning('Campo Hora y Minutos es necesario');
        return false
      }
      if (!this.data.r_fecha_letra){
        this.sweetAlertWarning('Campo Fecha en Letra es requerido');
        return false
      }
      return true
      return true
    },
    async validateRequiredLista (){
      if (!this.data.documento_url){
        this.sweetAlertWarning('Campo de adjuntar documento es necesario');
        return false
      }
      return true
    },
    async validateRequiredOficioVigilancia (){
      if (!this.data.numero_oficio){
        this.sweetAlertWarning('Campo Número de Oficio es necesario');
        return false
      }
      return true
    },
    async submitForm() {
      const validateData = await this.validateRequired();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createJAMinutes(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormPublicacion() {
      const validateData = await this.validateRequiredPublicacion();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createJAPublication(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormRetiro() {
      const validateData = await this.validateRequiredRetiro();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createJARetirement(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormLista() {
      const validateData = await this.validateRequiredLista();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createJAAttendance(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormOficioVigilancia() {
      const validateData = await this.validateRequiredOficioVigilancia();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createJASurveillanceUnit(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
